var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Login", {
    staticClass: "login",
    attrs: { redirectParams: _vm.redirectParams }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }